import { Injectable, NgZone } from '@angular/core';
import {
   ActivatedRoute,
   ActivatedRouteSnapshot,
   NavigationEnd,
   NavigationExtras,
   NavigationStart,
   ResolveEnd,
   Router,
   RouterStateSnapshot
} from '@angular/router';
import { ThirdPartyAuthProvider } from 'app/model/enums';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})
export class RouterService {
   private currentSnapshot: RouterStateSnapshot;
   private navigationStart: number;

   private onPageLoadedSource = new Subject<any>();
   public readonly onPageLoaded$: Observable<any> = this.onPageLoadedSource.asObservable();

   public currentPageContext: PageData;

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private ngZone: NgZone
   ) {
      this.subscribe();
   }

   public getProviderRedirectUrl(provider: ThirdPartyAuthProvider, extras?: NavigationExtras) {
      return this.router.createUrlTree(['/auth/redirect', provider], extras).toString();
   }

   public async navigateToProviderRedirect(provider: ThirdPartyAuthProvider, extras?: NavigationExtras) {
      await this.ngZone.run(async () => {
         await this.router.navigate(['/auth/redirect', provider], extras);
      });
   }

   private subscribe() {
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
         this.navigationStart = performance.now();
      });

      this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
         const activatedComponent = this.getActivatedComponent(event.state.root);
         if (activatedComponent) {
            this.currentSnapshot = event.state;
         } else {
            this.currentSnapshot = null;
         }
      });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
         if (!this.currentSnapshot) return;

         const duration = this.navigationStart > 0 ? Math.round(performance.now() - this.navigationStart) : 0;
         const name = this.getRouteTemplate(this.currentSnapshot.root);

         this.currentPageContext = {
            name,
            duration,
            routeSnapshot: this.currentSnapshot,
            url: this.currentSnapshot.url
         };
         this.onPageLoadedSource.next(this.currentPageContext);
      });
   }

   private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
      if (snapshot.firstChild) {
         return this.getActivatedComponent(snapshot.firstChild);
      }

      return snapshot.component;
   }

   private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
      let path = '';
      if (snapshot.routeConfig) {
         path += snapshot.routeConfig.path;
      }

      if (snapshot.firstChild) {
         return path + this.getRouteTemplate(snapshot.firstChild);
      }

      return path;
   }
}

export class PageData {
   public name: string;
   public url: string;
   public duration?: number;
   public routeSnapshot: RouterStateSnapshot;
}
